<template>
  <th
    role="columnheader"
    scope="col"
    :class="[
      props.column.class || props.styles.thead.th,
      { 'cursor-pointer': isColumnSortable },
    ]"
    :aria-sort="ariaSort"
    @click="handleHeaderCellClick"
  >
    <div class="flex items-center justify-center gap-1 text-left leading-snug">
      <slot
        :name="`headerCell-${props.column.name}`"
        v-bind="{ column }"
      >
        {{ props.column.value }}
      </slot>

      <UiIcon
        v-if="isColumnSortable"
        name="arrow-down"
        width="11"
        height="7"
        class="shrink-0"
        :class="{
          'text-blue-150': isFieldSorted,
          '!rotate-180':
            props.sortOrder === SortOrder.ASCENDING && isFieldSorted,
        }"
      />
    </div>
  </th>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import type { HeaderCellProps, SortOptions, SortOrderValues, TableCellClickEmitValue } from './UiBaseTable.types'
import {
  SortOrder,
  SortOrderDictionary,
} from './UiBaseTable.types'
import { getColumnOrColumnPropValue } from './UiBaseTable.utils'

const props = withDefaults(defineProps<HeaderCellProps>(), {
  sortField: null,
  sortOrder: SortOrder.NONE,
})

const emit = defineEmits<{
  (e: 'sort', sortOptions: SortOptions): void
  (e: 'click:column', value: TableCellClickEmitValue)
  (e: 'click:checkbox')
}>()

function handleHeaderCellClick(event: MouseEvent) {
  emit('click:column', {
    originalEvent: event,
    column: props.column,
  })
}

const sortState = computed(() => {
  let sorted = false
  let sortOrder: SortOrderValues = SortOrder.NONE

  sorted = Boolean(
    props.sortField
    && props.sortField === (getColumnPropertyValue('name') as string),
  )
  sortOrder = sorted ? props.sortOrder : 0

  return {
    sorted,
    sortOrder,
  }
})

const ariaSort = computed(() => {
  if (!isColumnSortable.value) {
    return
  }

  const { sorted, sortOrder } = sortState.value

  if (sorted && sortOrder < 0) {
    return SortOrderDictionary.DESCENDING
  }
  else if (sorted && sortOrder > 0) {
    return SortOrderDictionary.ASCENDING
  }
  else {
    return SortOrderDictionary.NONE
  }
})

function getBodyColumnOrColumnPropValue(columnName: string, propName?: string) {
  return getColumnOrColumnPropValue(props.body, columnName, propName)
}

function getColumnPropertyValue(prop: string) {
  return getBodyColumnOrColumnPropValue(props.column.name, prop)
}

const isFieldSorted = computed(() => props.column.name === props.sortField)

const isColumnSortable = computed(
  () =>
    getBodyColumnOrColumnPropValue(props.column.name, 'sortable')
    ?? props.column.sortable,
)
</script>
