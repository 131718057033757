import type { StyleValue } from 'vue'

interface TableElementsClassConfig {
  table: {
    el: string
  }
  thead: {
    el: string
    tr: string
    th: string
  }
  tbody: {
    el: string
    tr: string
    td: string
  }
  tfoot: {
    el: string
    tr: string
    td: string
  }
}

export interface Column {
  class?: string
  name: string
  sortable?: boolean
  expandable?: boolean
  value: any
  visible?: boolean
}

export interface Row {
  class?: string
  name: string
  item?: any
  sortable?: boolean
  expandable?: boolean
  value: Column[]
}

export type GetColumnOrColumnPropValue = (
  columns: Row[],
  columnName: string,
  propName?: string,
) => undefined | string | Column

export type GetBodyColumnOrColumnPropValue = (
  columnName: string,
  propName?: string,
) => undefined | string | Column

export enum SortOrder {
  ASCENDING = 1,
  DESCENDING = -1,
  NONE = null,
}

export type SortOrderValues =
  | SortOrder.ASCENDING
  | SortOrder.DESCENDING
  | SortOrder.NONE

export interface SortOptions {
  sortField: string
  sortOrder: SortOrderValues
}

export enum SortOrderDictionary {
  ASCENDING = 'descending',
  DESCENDING = 'ascending',
  NONE = 'none',
}

export type TableRowStyle = (row: Row) => StyleValue | undefined
export type TableRowClass = (row: Row) => object | string | undefined
export type TableCellStyle = (cell: Column, row: Row) => StyleValue | undefined
export type TableCellClass = (
  cell: Column,
  row: Row,
) => object | string | undefined

export interface UiBaseTableProps {
  headers?: Column[]
  loading?: boolean
  body: Row[]
  sortField?: string | null
  sortOrder?: SortOrderValues
  defaultSortOrder?: SortOrderValues
  removableSort?: boolean
  multiselect?: boolean
  expandedRows?: string[]
  selectedRows?: string[]
  highlightedRows?: string[]
  isSelectAllCheckboxSelected?: boolean
  isSelectAllCheckboxIndeterminate?: boolean
  showRowNumber?: boolean
  styles?: Partial<TableElementsClassConfig>
  rowStyle?: TableRowStyle
  rowClass?: TableRowClass
  rowCellStyle?: TableCellStyle
  rowCellClass?: TableCellClass
  styleVariant?: 'none' | 'default' | 'tight'
}

export type SortField = string | null

export interface HeaderProps {
  sortField?: SortField
  sortOrder?: SortOrderValues
  headers: Column[]
  body: Row[]
  isCheckboxIndeterminate?: boolean
  allRowsSelected?: boolean
  multiselect?: boolean
  styles: TableElementsClassConfig
}

export interface HeaderCellProps {
  sortField?: SortField
  sortOrder?: SortOrderValues
  column: Column
  columnIndex: number
  styles: TableElementsClassConfig
  body: Row[]
}

export interface BodyProps {
  loading?: boolean
  body: Row[]
  totalColumnCount: number
  multiselect?: boolean
  showRowNumber?: boolean
  selectedRows?: string[]
  expandedRows?: string[]
  highlightedRows?: string[]
  styles: TableElementsClassConfig
  rowStyle?: TableRowStyle
  rowClass?: TableRowClass
  rowCellStyle?: TableCellStyle
  rowCellClass?: TableCellClass
}

export interface BodyCellProps {
  column: Column
  columnIndex: number
  rowStyle?: TableRowStyle
}

export interface TableCellClickEmitValue {
  originalEvent: Event | MouseEvent | PointerEvent
  column: Column
}
